<template>
  <form @submit.prevent="confirm">
    <div class="modal-card">
      <header class="modal-card-head has-background-danger">
        <p class="modal-card-title has-text-white has-text-centered">
          {{ action }}
        </p>
      </header>

      <section class="modal-card-body">
        <p class="has-margin-bottom-150">
          {{ message }} To confirm this action please enter the code
          <code>{{ pin }}</code> below.
        </p>

        <b-field>
          <b-input
            ref="pin"
            v-model="confirmPin"
            :placeholder="`Enter ${pin}`"
            :disabled="processing"
            type="tel"
          />
        </b-field>
      </section>

      <footer class="modal-card-foot has-content-justified-between">
        <button
          :disabled="processing"
          type="button"
          class="button is-light"
          @click="close"
        >
          Cancel
        </button>

        <button
          :disabled="!validForm || processing"
          :class="{ 'is-loading': processing }"
          type="submit"
          class="button is-danger"
        >
          {{ action }}
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    action: { required: true, type: String },
    message: { required: true, type: String },
    autoClose: { required: false, type: Boolean, default: true }
  },

  data() {
    return {
      pin: `${this.$_.random(1000, 9999)}`,
      confirmPin: ``,
      processing: false
    };
  },

  computed: {
    validForm() {
      return this.pin === this.confirmPin;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.pin && this.$refs.pin.$el.querySelector("input").focus();
    });
  },

  methods: {
    confirm() {
      this.processing = true;
      this.$emit("confirmed");
      if (this.autoClose) this.close();
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
